import classnames from "classnames";

import { validateMaxLength } from "../utils/validators";
import useField, { FieldHookProps } from "../hooks/useField";
import FieldWrapper from "./FieldWrapper";

interface TextAreaFieldProps extends FieldHookProps {
  autoComplete?: "on" | "off";
  rows?: number;
}

const TextAreaField = ({
  maxLength,
  validators = [],
  rows = 5,
  ...props
}: TextAreaFieldProps) => {
  const field = useField({
    validators: [...validators, maxLength && validateMaxLength(maxLength)],
    ...props,
  });

  return (
    <FieldWrapper {...field}>
      <textarea
        className={classnames("form-field", {
          "form-field--error": !!field.error,
        })}
        disabled={field.disabled}
        id={field.name}
        name={field.name}
        onChange={field.onChange}
        onBlur={field.onBlur}
        placeholder={field.placeholder}
        autoComplete={field.autoComplete}
        value={field.value}
        rows={rows}
      />
    </FieldWrapper>
  );
};

TextAreaField.defaultProps = {
  autoComplete: "off",
  rows: 5,
};

export default TextAreaField;

import Avatar from "modules/common/components/Avatar";
import Obfuscate from "react-obfuscate";

const TeamCollectionItem = ({ employee }) => {
  return (
    <div className="col-sm-6 col-lg-4">
      <Avatar image={employee?.image} />
      <div>
        <b>{employee.name}</b>
      </div>
      <div>{employee.title}</div>
      {employee.tel && (
        <div>
          <Obfuscate href={`tel:${employee.tel}`}>{employee.tel}</Obfuscate>
        </div>
      )}
      {employee.email && (
        <small className="d-block">
          <Obfuscate href={`mailto:${employee.email}`}>
            {employee.email}
          </Obfuscate>
        </small>
      )}
    </div>
  );
};

export default TeamCollectionItem;

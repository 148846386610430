import { useQuery, gql } from "@apollo/client";
import { useIntl } from "react-intl";

export const GET_INFOTEXT = gql`
  query cmsInfoText($lang: String, $filter: JsonType) {
    infoTextModel(locale: $lang, sort: { endDateTime: -1 }, filter: $filter) {
      _id
      text
      startDateTime
      endDateTime
      link
      backgroundColor
      textColor
      closable
    }
    me {
      _id
    }
  }
`;

const convertCMSDateTimeToISO = (dateTime) => {
  // dateTime format: 2023-06-07 12:16
  // we assume timezone is the same like on the browser so it works fine in
  // addis abebba and zurich when testing.

  if (!dateTime) return null;
  const [date, time] = dateTime.split(" ");
  const [year, month, day] = date.split("-");
  const [hour, minute] = time.split(":");

  return new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(day, 10),
    parseInt(hour, 10),
    parseInt(minute, 10),
  );
};

const useCMSInfoText = () => {
  const { locale } = useIntl();
  const lang = locale.split("-")[0];

  const filter: {
    [key: string]: any;
  } = {
    $and: [{ [`text${lang === "de" ? "" : `_${lang}`}`]: { $ne: null } }],
  };

  const { data, loading, error } = useQuery(GET_INFOTEXT, {
    variables: {
      lang,
      filter,
    },
    fetchPolicy: "cache-and-network",
  });
  const messages = (data?.infoTextModel || []).filter((obj) => {
    const now = new Date();
    const start = convertCMSDateTimeToISO(obj.startDateTime) || now;
    const end = convertCMSDateTimeToISO(obj.endDateTime) || now;
    return now >= start && now <= end;
  });

  return {
    loading,
    error,
    messages,
    userId: data?.me?._id,
  };
};

export default useCMSInfoText;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (!process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID) return;
  window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (action, payload) => {
  if (!process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID) return;
  window.gtag("event", action, payload);
};

export const config = (payload) => {
  if (!process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID) return;
  window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID, payload);
};

import React from "react";
import { useIntl } from "react-intl";
import Icon from "../../common/components/Icon";
import StoryListItem from "./StoryListItem";
import useCMSStories from "../hooks/useCMSStories";

const StoryList = ({
  type = "simple",
  limit = 0,
  disableLoadMore = false,
  offset = 0,
}) => {
  const intl = useIntl();
  const { stories, loading, hasMore, loadMore } = useCMSStories({
    limit,
    offset,
  });

  const typeClassMap = {
    simple: "col-md-6 mb-4",
    homepage: "col-md-4 mb-4 mb-md-3",
    storiespage: "col-md-4 mb-4",
  };

  return (
    <>
      <div className="row">
        {stories.map((item, anchor) => (
          <StoryListItem
            key={item._id}
            className={typeClassMap[type]}
            story={item}
          />
        ))}
      </div>
      {loading ? <Icon className="loader-wrap" icon="loading" /> : ""}
      {!loading && !disableLoadMore && hasMore && (
        <div className="text-center py-4">
          <button
            aria-label={intl.formatMessage({
              id: "load_more",
              defaultMessage: "Weitere laden ...",
            })}
            type="button"
            className="button button--primary button--big"
            onClick={() => loadMore()}
          >
            <Icon
              icon="Pfeil-nach-unten"
              className="mr-2"
              fill="currentColor"
            />
            {intl.formatMessage({
              id: "load_more",
              defaultMessage: "Weitere laden ...",
            })}
          </button>
        </div>
      )}
    </>
  );
};

export default StoryList;

import React, { useState } from "react";
import T from "prop-types";

const Obfuscate = (props) => {
  const {
    element,
    children,
    tel,
    sms,
    facetime,
    email,
    href,
    headers,
    obfuscate,
    obfuscateChildren,
    linkText,
    style,
    onClick,
    ...others
  } = props;

  const [humanInteraction, setHumanInteraction] = useState(false);
  const renderedLink = children || tel || sms || facetime || email || href;
  const Component = element;

  const generateLink = () => {
    let link = "";

    if (email) {
      link = email.toLowerCase();
      if (!link.startsWith("mailto:")) {
        link = `mailto:${link}`;
      }
      if (headers) {
        const headerParams = new URLSearchParams(headers).toString();
        link += `?${headerParams}`;
      }
    } else if (tel) {
      link = tel.startsWith("tel:") ? tel : `tel:${tel}`;
    } else if (sms) {
      link = sms.startsWith("sms:") ? sms : `sms:${sms}`;
    } else if (facetime) {
      link = facetime.startsWith("facetime:")
        ? facetime
        : `facetime:${facetime}`;
    } else if (href) {
      link = href;
    } else if (typeof children === "string") {
      link = children;
    }

    return link;
  };

  const clickProps =
    Component === "a"
      ? {
          href:
            humanInteraction === true || obfuscate === false
              ? generateLink()
              : linkText || "#",
        }
      : {};

  return (
    <Component
      onFocus={() => setHumanInteraction(true)}
      onMouseOver={() => setHumanInteraction(true)}
      onContextMenu={() => setHumanInteraction(true)}
      {...others}
      {...clickProps}
    >
      {renderedLink}
    </Component>
  );
};

Obfuscate.propTypes = {
  element: T.string,
  children: T.node,
  tel: T.string,
  sms: T.string,
  facetime: T.string,
  email: T.string,
  href: T.string,
  headers: T.shape({}),
  obfuscate: T.bool,
  obfuscateChildren: T.bool,
  linkText: T.string,
  style: T.shape({}),
  onClick: T.func,
  className: T.string,
};

Obfuscate.defaultProps = {
  element: "a",
  children: undefined,
  tel: undefined,
  sms: undefined,
  facetime: undefined,
  email: undefined,
  href: undefined,
  headers: undefined,
  obfuscate: undefined,
  obfuscateChildren: undefined,
  linkText: undefined,
  style: {},
  onClick: undefined,
  className: "",
};

export default Obfuscate;

/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useFormik, FormikValues, FormikErrors, FormikTouched } from "formik";
import { useIntl } from "react-intl";
import useMaintenanceMode from "../../layout/hooks/useMaintenanceMode";
import clean from "../utils/clean";

/**
 * @param onSubmitSuccess Return `false` to skip redirect
 */
const useForm = ({
  submit,
  getSubmitErrorMessage = () => "",
  onSubmitSuccess = () => true,
  initialValues,
  initialErrors,
  initialTouched,
  enableReinitialize,
  validate,
}: {
  submit: (
    variables: Record<string, any>,
  ) => Promise<{ success: boolean; data?: any; error?: any } | boolean>;
  getSubmitErrorMessage?: (error?: Error | any) => Promise<string> | string;
  onSubmitSuccess?: (
    result: boolean,
    values: FormikValues,
  ) => Promise<boolean> | boolean;
  initialValues: FormikValues;
  initialTouched?: FormikTouched<FormikValues>;
  initialErrors?: FormikErrors<FormikValues>;
  enableReinitialize?: boolean;
  validate?: (
    values: FormikValues,
  ) => void | object | Promise<FormikErrors<FormikValues>>;
}) => {
  const intl = useIntl();
  const { isActive } = useMaintenanceMode();
  const [submitError, setSubmitError] = useState("");

  const onSubmit = async (values) => {
    setSubmitError("");

    try {
      const result = await submit(clean(values));
      const success =
        (typeof result === "boolean" ? result : result?.success) ?? false;

      if (success) {
        await onSubmitSuccess(success, values);
      } else {
        const error = typeof result === "boolean" ? !result : result?.error;
        setSubmitError(
          (await getSubmitErrorMessage(error)) ||
            intl.formatMessage(
              {
                id: "error_server",
                defaultMessage:
                  "Es ist ein Problem auf dem Server aufgetreten ({details}). Bitte versuchen Sie es später nochmals.",
              },
              { details: error?.message || "" },
            ),
        );
      }
    } catch (error) {
      // Do not use errors as control flow: Catch known/expected errors and only throw unexpected errors
      console.error(error); // eslint-disable-line
      setSubmitError(
        (await getSubmitErrorMessage(error)) ||
          intl.formatMessage(
            {
              id: "error_server",
              defaultMessage:
                "Es ist ein Problem auf dem Server aufgetreten ({details}). Bitte versuchen Sie es später nochmals.",
            },
            { details: error?.message || "" },
          ),
      );
    }
  };

  const formik = useFormik({
    initialValues,
    initialErrors,
    initialTouched,
    enableReinitialize,
    onSubmit,
    validate,
  });

  return {
    submitError,
    formik,
    setSubmitError,
    disabled: isActive,
  };
};

export default useForm;

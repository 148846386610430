import classNames from "classnames";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

const getHash = () =>
  typeof window !== "undefined"
    ? decodeURIComponent(window.location.hash.replace("#", ""))
    : undefined;

const ContentNavigation = ({ idMap }) => {
  const { formatMessage } = useIntl();
  const [hash, setHash] = useState(undefined);

  useEffect(() => {
    const handleHashChange = () => {
      setHash(getHash());
    };
    setHash(getHash());
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  if (!idMap?.length) return null;

  return (
    <aside className="mx-3 mb-lg-5 col-md-8 mx-md-auto offset-md-4 col-lg-3 aside-anchor-box box-shadow br-9 px-3 pt-3 pb-2">
      {formatMessage({
        id: "content-anchor-navigation-title",
        defaultMessage: "Inhalt",
      })}
      <ul className="font-size-inbetween border-top pt-3 pl-3">
        {idMap.map((val) => {
          const [id] = Object.keys(val);
          const [text] = Object.values(val) as string[];
          return (
            <li
              key={id}
              className={classNames(`content-anchor-nav-link my-2`, {
                active: hash === id,
              })}
            >
              <a href={`#${id}`}>{text}</a>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default ContentNavigation;

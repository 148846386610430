import { ApolloClient, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "crypto-hash";
import apolloCache, { resetCache } from "./apolloCache";

const allowedHeaders = [
  "accept-language",
  "pragma",
  "user-agent",
  "x-forwarded-for",
  "x-forwarded-host",
  "x-forwarded-port",
  "x-forwarded-proto",
  "x-forwarded-server",
  "x-mapihttpcapability",
  "x-real-ip",
  "x-user-identity",
  "apollo-require-preflight",
  "host",
  "cookie",
  "authorization",
];

const setLocale = (headers, locale) => {
  const newHeaders = {
    ...Object.fromEntries(
      Object.entries(headers).filter(([key]) => {
        return allowedHeaders.includes(key.toLowerCase());
      }),
    ),
    "accept-language": locale,
  };
  return { headers: newHeaders };
};

const getApolloClient = ({ headers = {}, locale }) => {
  const uri =
    typeof window === "undefined"
      ? process.env.UNCHAINED_ENDPOINT
      : `/api/graphql`;

  const localeLink = setContext((_, { headers: contextHeaders = {} }) => {
    return setLocale(
      { ...headers, ...contextHeaders, "apollo-require-preflight": 1 },
      locale,
    );
  });

  const persistedQueryLink = createPersistedQueryLink({
    sha256,
    useGETForHashedQueries: true,
  });

  const httpLink = createUploadLink({
    uri,
    credentials: "same-origin",
    fetch,
    ssrMode: typeof window === "undefined",
    includeExtensions: true,
  });

  const cache = apolloCache(locale);
  const apolloClient = new ApolloClient({
    connectToDevTools: typeof window !== "undefined",
    ssrMode: typeof window === "undefined",
    link:
      process.env.NODE_ENV !== "development"
        ? ApolloLink.from([persistedQueryLink, localeLink, httpLink])
        : ApolloLink.from([localeLink, httpLink]),
    assumeImmutableResults: true,
    cache,
  });

  apolloClient.onResetStore(() => {
    resetCache(cache);
  });

  return apolloClient;
};

export default getApolloClient;

import { useQuery, gql } from "@apollo/client";
import { useIntl } from "react-intl";

import useFakeLoadMore from "../../common/hooks/useFakeLoadMore";
import makeFormattedDateSorter from "../../common/utils/makeFormattedDateSorter";
import makeFormattedFutureDateFilter from "../../common/utils/makeFormattedFutureDateFilter";
import normalizeDateFormat from "modules/common/utils/normalizeCockpitDateField";

const DEFAULT_LIMIT = 12;

export const GET_STORIES_QUERY = gql`
  query cmsGetStories($lang: String, $filter: JsonType) {
    newsModel(locale: $lang, filter: $filter) {
      _id
      title
      subTitle
      slug
      publicationDate
      image
      title_slug
    }
  }
`;

export const GET_STORY_QUERY = gql`
  query cmsGetStory($lang: String, $filter: JsonType) {
    newsModel(locale: $lang, filter: $filter) {
      _id
      title
      subTitle
      slug
      publicationDate
      image
      title_slug
      content
    }
  }
`;

const organizeStories = (data, unfiltered, offset, limit) => {
  const normalizedLimit = offset * limit;
  return unfiltered
    ? data?.newsModel || []
    : [...(data?.newsModel || [])]
        .map(normalizeDateFormat("publicationDate"))
        .filter(makeFormattedFutureDateFilter("publicationDate"))
        .sort(makeFormattedDateSorter("publicationDate", true))
        .slice(0, normalizedLimit);
};

const useCMSStories = ({
  limit: givenLimit = DEFAULT_LIMIT,
  offset: givenOffset = 1,
  unfiltered = false,
}) => {
  const { locale: lang } = useIntl();

  const languageFilterField =
    lang === "de" ? "title_slug" : `title_slug_${lang}`;
  const { data, loading, error } = useQuery(GET_STORIES_QUERY, {
    variables: {
      lang,
      filter: {
        $and: [
          { [languageFilterField]: { $ne: null } },
          { [languageFilterField]: { $ne: "" } },
        ],
      },
    },
  });

  const { offset, loadMore, shouldShowLoadMore } = useFakeLoadMore(
    givenOffset || 1,
  );
  const normalizedLimit = givenLimit || DEFAULT_LIMIT;
  return {
    loading,
    error,
    stories: organizeStories(data, unfiltered, offset, normalizedLimit),
    loadMore,
    hasMore: shouldShowLoadMore(data?.newsModel?.length / normalizedLimit),
  };
};

export const preFetchStories = async (apolloClient, { locale }) => {
  const languageFilterField =
    locale === "de" ? "title_slug" : `title_slug_${locale}`;
  const filter: any = {
    $and: [
      { [languageFilterField]: { $ne: null } },
      { [languageFilterField]: { $ne: "" } },
    ],
  };

  const { data } = await apolloClient.query({
    query: GET_STORIES_QUERY,
    variables: {
      lang: locale,
      filter,
    },
  });

  return data?.newsModel || [];
};

export const preFetchStory = async (apolloClient, { locale, slugAndId }) => {
  const languageFilterField =
    locale === "de" ? "title_slug" : `title_slug_${locale}`;
  const filter: any = {
    $and: [
      { [languageFilterField]: { $ne: null } },
      { [languageFilterField]: { $ne: "" } },
    ],
  };

  if (slugAndId) {
    const id = [...slugAndId.split("-")].pop();

    filter.$or = [{ _id: id }, { [languageFilterField]: slugAndId }];
  }

  const { data } = await apolloClient.query({
    query: GET_STORY_QUERY,
    variables: {
      lang: locale,
      filter,
    },
  });
  return (data?.newsModel || [])?.[0];
};

export default useCMSStories;

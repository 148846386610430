import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import RoutesContext from "../RoutesContext";
// import LocalizedHead from "./LocalizedHead";
import ModalWrapper from "../../modal/components/ModalWrapper";
import useCMSInfoText from "modules/layout/hooks/useCMSInfoText";
import publicareFonts from "modules/common/fonts/publicareFonts";

const InfoText = dynamic(() => import("../../layout/components/InfoText"), {
  ssr: false,
});

const CurrentPageWrapper = ({
  component: Component,
  locale,
  routes,
  ...props
}) => {
  const [bodyOverflowHidden, setBodyOverflowHidden] = useState(false);
  const [isCartToggled, setCartToggled] = useState(false);
  const [isClientChooserToggled, setUserToggled] = useState(false);
  const { messages, userId } = useCMSInfoText();

  const [isShiftPressed, setIsShiftPressed] = useState(false);
  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === "M" && e.ctrlKey) {
        setIsShiftPressed(!isShiftPressed);
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [isShiftPressed]);

  return (
    <RoutesContext.Provider
      value={{ // eslint-disable-line
        isCartToggled,
        setCartToggled,
        isClientChooserToggled,
        setUserToggled,
        bodyOverflowHidden,
        setBodyOverflowHidden,
        isShiftPressed,
      }}
    >
      <main className={publicareFonts.className}>
        <ModalWrapper>
          <InfoText messages={messages} userId={userId} />
          <Component {...props} />
          {/* eslint-disable-next-line */}
        <style jsx global>
            {`
              @media (max-width: 1023px) {
                body {
                  ${bodyOverflowHidden ? "overflow: hidden;" : ""}
                }
              }
            `}
          </style>
        </ModalWrapper>
      </main>
    </RoutesContext.Provider>
  );
};

export default CurrentPageWrapper;

import { gql, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import AssortmentFragment from "../fragment/AssortmentFragment";

export const RootCategories = gql`
  query RootCategories(
    $locale: String
    $limit: Int
    $tags: [LowerCaseString!]
  ) {
    assortments(limit: $limit, tags: $tags) {
      ...AssortmentFragment
    }
  }
  ${AssortmentFragment}
`;

const useCategories = ({ limit = 5, tags = null }) => {
  const intl = useIntl();
  const locale = intl.locale.split("-")[0];
  const { loading, error, data } = useQuery(RootCategories, {
    variables: {
      locale,
      limit,
      tags,
    },
  });

  const cmsCategorys = data?.assortments || [];

  return {
    loading,
    error,
    categories: [...cmsCategorys],
  };
};

export const preFetchCategories = async (
  apolloClient,
  { locale, limit = 5 },
) => {
  const { data: { assortments = [] } = {} } = await apolloClient.query({
    query: RootCategories,
    variables: { locale, limit, tags: null },
  });
  return assortments;
};
export default useCategories;

import { useContext } from "react";
import { useIntl } from "react-intl";
import { useQuery, gql } from "@apollo/client";
import RoutesContext from "../../navigation/RoutesContext";

const MaintenanceModeQuery = gql`
  query MaintenanceMode {
    shopInfo {
      _id
      publicareMaintenanceMode {
        _id
        isActive
        downUntil
        customText
      }
    }
  }
`;

const useMaintenanceMode = () => {
  const intl = useIntl();
  const { data: { shopInfo } = {}, loading } = useQuery(MaintenanceModeQuery);
  const ctx = useContext(RoutesContext);

  const remoteMaintenanceMode =
    (shopInfo && shopInfo.publicareMaintenanceMode) || {};

  const maintenanceMode = {
    isActive: remoteMaintenanceMode.isActive,
    downUntil: remoteMaintenanceMode.downUntil || new Date(),
    customText:
      remoteMaintenanceMode.customText ||
      intl.formatMessage({
        id: "maintenance",
        defaultMessage:
          "Wegen Wartungsarbeiten sind Online-Bestellungen und Login zurzeit nicht möglich. Sie können zwischenzeitlich telefonisch bestellen. Danke für Ihr Verständnis.",
      }),
  };

  if (ctx.isShiftPressed) {
    // invert maintenance mode!
    maintenanceMode.isActive = !maintenanceMode.isActive;
    console.warn(
      `Ctrl + Shift + m pressed, maintenance mode manually inverted to: ${maintenanceMode.isActive}`,
    );
  }

  return {
    loading,
    ...maintenanceMode,
  };
};

export default useMaintenanceMode;

import React from "react";
import useFormContext from "../hooks/useFormContext";

const ErrorMessage = ({ message }) => {
  return React.isValidElement(message) ? (
    message
  ) : (
    <span dangerouslySetInnerHTML={{ __html: message }} />
  );
};

const FormErrors = ({ submitError: submitErrorText = "" }) => {
  const {
    formik: { errors: fieldErrors, submitCount },
    submitError,
  } = useFormContext();

  const showErrors =
    (submitError && (submitCount > 0 || Object.keys(fieldErrors).length > 0)) ||
    submitErrorText;

  return showErrors ? (
    <div className="error-message ml-4">
      <ul>
        {(submitError || submitErrorText) && (
          <li className="disabled">
            <ErrorMessage message={submitError || submitErrorText} />
          </li>
        )}
        {Object.entries(fieldErrors).map(([name, message]) => (
          <li key={name} className="disabled">
            <button
              type="button"
              className="no-button form-errors-item"
              aria-label="Error button"
              onClick={() => document.getElementById(name).focus()}
            >
              <ErrorMessage message={message} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default FormErrors;

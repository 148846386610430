import React from "react";

const Avatar = ({ image, className = "mt-5" }) => {
  return (
    <img
      className={`rounded-circle border-0 w-140px ${className}`}
      src={image?.path || "/static/img/avatar.png"}
      onError={(event) => {
        if (event?.target instanceof HTMLImageElement) {
          event.target.src = "/static/img/avatar.png";
          element.onerror = null;
        }
      }}
    />
  );
};

export default Avatar;

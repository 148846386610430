import DOMRenderer from "modules/layout/components/DOMRenderer";

const RichTextComponent = ({ data }) => {
  return (
    <DOMRenderer
      htmlString={data?.html}
      className={`${data?.class || "rich-text-component"}`}
    />
  );
};

export default RichTextComponent;

import { useQuery, gql } from "@apollo/client";
import { useIntl } from "react-intl";

const CmsGetFAQ = gql`
  query cmsGetFAQ($lang: String, $filter: JsonType) {
    faqModel(locale: $lang, filter: $filter, sort: { _o: 1 }) {
      _id
      question
      answer
    }
  }
`;

const useCMSFAQs = ({ forceLocale = "" } = {}) => {
  const { locale } = useIntl();
  const languageFilterField =
    forceLocale === "de" || locale === "de"
      ? "answer"
      : `answer_${forceLocale || locale}`;
  const { data, loading, error } = useQuery(CmsGetFAQ, {
    fetchPolicy: "cache-and-network",
    variables: {
      lang: forceLocale || locale,
      filter: {
        $and: [
          { [languageFilterField]: { $ne: null } },
          { [languageFilterField]: { $ne: "" } },
        ],
      },
    },
  });

  return {
    loading,
    error,
    faqs: data?.faqModel || [],
  };
};

export const preFetchFAQs = async (apolloClient, { locale }) => {
  const languageFilterField = locale === "de" ? "answer" : `answer_${locale}`;
  const { data } = await apolloClient.query({
    query: CmsGetFAQ,
    variables: {
      lang: locale,
      filter: {
        $and: [
          { [languageFilterField]: { $ne: null } },
          { [languageFilterField]: { $ne: "" } },
        ],
      },
    },
  });
  return data?.faqModel || [];
};

export default useCMSFAQs;
